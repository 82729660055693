//NAVBAR SCROLL INDICATOR
var scrollSpy = new bootstrap.ScrollSpy(document.body, {
  target: '#navbar-scroll'
});


//NAVBAR SCROLL INDICATOR

/*window.onscroll = function() {scrollFunction()}; 
function scrollFunction() {
  if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
    
    document.getElementById("navbar-scroll").style.background = "#fff";
  } else {
    document.getElementById("navbar-scroll").style.background = "none";
  }
}*/



//Get the button
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20
  ) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

